import React, { Component } from "react"
import SEO from "../components/seo"

class MerciPage extends Component {
  render() {
    // const data = this.props.data

    return (
      <>
        <SEO title="Anarchy in the kitchen - Remerciements" description="Anarchy in the kitchen - Page de Remerciements"/>
        <div className="maincontent center">
          <h1 data-text-shadow='Merci !'>Merci !</h1>
          <h4>Votre message sera traité dans les meilleurs delais.</h4>
        </div>
      </>
    )
  }
}

export default MerciPage

